<template>
  <main class="main">
    <NuxtLoadingIndicator />
    <MainTopbar />

    <div class="main-content">
      <SidebarsDefault class="hidden sm:flex flex-col w-52 justify-between pb-8" />
      <div class="w-full">
        <slot />
      </div>
    </div>
    <Alert />
    <ModalBase />
  </main>
</template>

<script lang="ts" setup>
import { useActivityChecker } from '~/src/composables/utils/useActivityChecker'
import Alert from '~/src/components/core/notification/Alert.vue'
import { totalHeaderHeight } from '~/src/constants'

const { startCounter } = useActivityChecker()
onMounted(() => startCounter())

const headerHeight = `${totalHeaderHeight}px`

useHead({
  title: 'Maplerad Dashboard',
  script: [{ src: 'script/intercom.js' }],
  link: [{ rel: 'icon', type: 'image', href: '/favicon.png' }],
})
</script>

<style scoped lang="scss">
.main {
  padding-top: v-bind(headerHeight);
  @apply h-screen bg-white px-5;
}

.main-content {
  @apply flex gap-8 overflow-hidden;
}
</style>
